import {applyMiddleware, createStore} from 'redux';
import logger from 'redux-logger';
// import thunk from 'redux-thunk';
import thunkMiddleware from 'redux-thunk';
import promise from 'redux-promise-middleware';
import reducer from './reducers';

// const middelware = applyMiddleware(promise(),thunk,logger);

// export default createStore(reducer,middelware);
export default createStore(reducer, applyMiddleware(thunkMiddleware, logger));






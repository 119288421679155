const initialState={
	fetching:false,
	fetched:false,
	delete: false,
	userdelete: false,
	suspend: false,
	api_response: false,
	garph:{},
	user:[]
}
export default function reducer(state=initialState, action){
	switch(action.type){
		case 'FETCH_USER':{
			return{...state,fetching:true}
			break;
		}
		case 'FETCH_USER_FULLFILLED':{
			return{...state, fetching:false, fetched:true, user:action.payload, delete: false, userdelete: false, suspend: false}
			break;
		}
		case 'FETCH_USER_DATA_FULLFILLED':{
			return{...state, fetching:false, fetched:true, user:action.payload, delete: false, userdelete: false, suspend: false}
			break;
		}
		case 'FETCH_USER_ClOSET_DATA_FULLFILLED':{
			return{...state, fetching:false, fetched:true, user:action.payload, delete: false, userdelete: false, suspend: false}
			break;
		}
		case 'DELETE_USER_ClOSET_DATA_FULLFILLED':{
			return{...state, fetching:false, fetched:true, user:action.payload, delete: true, userdelete: false, suspend: false}
			break;
		}
		case 'DELETE_USER_FULLFILLED':{
			return{...state, fetching:false, fetched:true, user:action.payload, delete: false, userdelete: true, suspend: false}
			break;
		}
		case 'SUSPEND_USER_FULLFILLED':{
			console.log("suspended")
			return{...state, fetching:false, fetched:true, user:action.payload, delete: false, userdelete: false, suspend: true}
			break;
		}
		case 'UPDATE_USER_FULLFILLED':{
			return{...state, fetching:false, fetched:true, user:action.payload, delete: false, userdelete: false, suspend: false,api_response: true}
			break;
		}
		case 'UPDATE_USER_PASSWORD_FULLFILLED':{
			return{...state, fetching:false, fetched:true, user:action.payload, delete: false, userdelete: false, suspend: false,api_response: true}
			break;
		}
		
		case 'DASHBOARD_FULLFILLED':{
			return{...state, fetching:false, fetched:true, user:action.payload, garph: action.payload.graph, delete: false, userdelete: false, suspend: false,api_response: true}
			break;
		}
		
		case 'FILTER_FULLFILLED':{
			return{...state, fetching:false, fetched:true, garph: action.payload.graph, delete: false, userdelete: false, suspend: false,api_response: true}
			break;
		}

		
	}
	return state;
}
import React, {Component} from 'react';
import {connect} from 'react-redux';
// import { Link } from 'react-router-dom';
import {fetchUserCloset, deleteUserClosetItem} from '../../actions/userActions'
import { MDBDataTableV5 } from 'mdbreact';
import Carousel from 'react-material-ui-carousel'
import {Paper} from '@material-ui/core'
import API_URL from "../../../Config"
import SweetAlert from 'react-bootstrap-sweetalert';

export class Closet extends Component{
    constructor(props) {
        super(props);
        let query = new URLSearchParams(window.location.search);
        this.state = {
            id: query.get("id"),
            show: true
        }
      }

	componentWillMount(){
        this.props.dispatch(fetchUserCloset(this.state.id));
    }

    deleteClosetItem = (userId, itemId) => {
        this.props.dispatch(deleteUserClosetItem(userId, itemId));
    }

    showLogs2 = () => {
        this.setState({show: false})
        this.props.dispatch(fetchUserCloset(this.state.id));
        
        
        
    }

  

	render(){

        const { users, is_delete } = this.props
        
            console.log(this.state)
        

		return (
        
            <div className="row">


               
                {is_delete &&
                  <SweetAlert
                    showCloseButton
                    title={"Item deleted Successfully"}
                    onConfirm={() => {this.showLogs2()}}
                    onCancel={(e) => {this.showLogs2()}}
                    customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
                    show={is_delete && this.state.show}
                    
                    />
                }
        
                { users && users.length==0 &&
                    <div className="col-md-12">
                        <div className="card card-profile">
                            <div className="card-body">
                            <h4 className="card-title">No Closet found </h4>
                            </div>
                        </div>
                    </div>
                }
                  {  users && users.length>0 &&
                           <div className="col-md-12">
                                <div class="card">
                                    <div class="card-header card-header-primary">
                                        <h4 class="card-title ">User Closet Management</h4>
                                        <p class="card-category"> </p>
                                    </div>
                                </div>
                            </div>
                    }
                {  users && users.length>0 && users.map((key, value)=>{

                    return(
                        <div className="col-md-4">
                            <div className="card card-profile">
                            <div className="card-body">
                            <Carousel>
                                            {
                                                key.item_images.map( (item, i) =>{
                                                    return(
                                                        <Paper>
                                                   <img src={API_URL.BASE_URL+item.image.url}></img>
                                            
                                                    </Paper>
                                                    )
                                                } )
                                            }
                            </Carousel>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="card-category text-gray">Item Name</h4>
                                    <h4 className="card-title">{key.title} </h4>
                                </div>
                                <div className="col-md-6">
                                    <h4 className="card-category text-gray">Category</h4>
                                    <h4 className="card-title">{key.item_category.name}</h4>
                                </div>
                            </div>
                             
                                <a href="javascript:;" onClick={()=>{this.deleteClosetItem(key.user_id, key.id)}} className="btn btn-primary btn-round">Delete Item</a>
                                
                                </div>
                            </div>
                        </div>

                    )
                })}
     
          </div> 
        
		);
	}
}

const mapStateToProps = (store) => {
   console.log("store-------", store.user)
	return{
        users: store.user.user.closetItem,
        is_delete: store.user.delete,
        // data_table:,
        userFetched: store.user.fetched,
	};
}

export default connect(mapStateToProps)(Closet)
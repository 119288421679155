import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {loginUser} from '../actions/loginAction';
import {connect} from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';


export class Login extends React.Component{
	constructor(props) {
    super(props);
    this.state = {email: '', password: '',role: 2, show: false};
  
  }

  handleChange(key, event){
    this.setState({[key]: event.target.value});
  }



  handleSubmit = (e) => {
    e.preventDefault()
   const {email,password, role}=this.state;
      let params={
        email: email, password: password, role: role
      };
    if(email==""){
      this.setState({show: true});
 
          
    }else{
      this.props.loginUser(params);
    }
      
       
    }

  
  
	render() {
    const { email, password } = this.state;
    return ( 
      <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <div className="card">
          <div className="card-header card-header-primary">
            <h4 className="card-title">Login</h4>
            {/* <p className="card-category">Sign In</p> */}
          </div>
          <SweetAlert
            showCloseButton
            title={"All fields are required."}
            onConfirm={() => this.setState({ show: false })}
            onCancel={() => this.setState({ show: false })}
            customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
            show={this.state.show}
            
            />
        
          <div className="card-body">
            <form onSubmit={(e)=>this.handleSubmit(e)}>
              <div className="row">
              
                <div className="col-md-12">
                <label className="">Email</label>
                  <div className="form-group">
                  
                    <input type="email" className="form-control" value={this.state.email} onChange={this.handleChange.bind(this, 'email')} required/>
                  </div>
                </div>
             
             
                <div className="col-md-12">
                <label className="">Password</label>
                  <div className="form-group">
                   
                    <input type="password" className="form-control" value={this.state.password} onChange={this.handleChange.bind(this, 'password')} required/>
                  </div>
                </div>
               
              </div>
       
         
              <button type="submit" className="btn btn-primary pull-right"  >Sign In</button>
              <div className="clearfix"></div>
            </form>
          </div>
        </div>
      </div>


      </div>

      
     
  
    	)
  }
}

const mapStateToProps = (store) => {

  return{
    login: store.login,
    token: store.login.token
  };
}

const mapDispatchToProps = (dispatch) => {
    return {
      loginUser : (params) => dispatch(loginUser(params) ),
       
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);

import axios from 'axios';
import API_URL from "../../Config"
import {Apiheader} from "../helpers/Apiheader"
import history from '../../history';

var configheaders = {
    headers: Apiheader()
}

export function loginUser(params){

	// return function(dispatch){
	// 	return {
	// 	type: "LOGIN",
	// 	payload: axios.post(API_URL+'/auth/sign_in', params )
	// 	}
	// }

		return function(dispatch){
		axios.post(API_URL.API_PATH+'/auth/sign_in', params )
			 .then((response)=>{
				 debugger
				 localStorage.setItem("access_token", response.data.token)
				 window.location.href = window.location.pathname
				//  history.push('/');
				dispatch ({type: 'LOGIN_FULFILLED',payload:response.data})
			}).catch(function(error){
				errorHandling(error)
			})
	}
}
	
const errorHandling = (error)=>{

	if(error.request.status!=401 && error.response){	
		alert(error.response.data.error.message)
	}

	if(error.request.status==401){
		alert("Your Session has been expired. Please try again later.")
		 localStorage.clear()
		 window.location.href="/"
	}

}



import React, {Component} from 'react';
import {connect} from 'react-redux';
// import{BrowserRouter,Route, Link,Switch} from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import Home from './Home';
import Dashboard from './Dashboard';
import Layout from './common/Layout';
import Usermanagement from './Usermanagement';
import Profile from './Users/Profile';
import Closet from './Users/Closet';
import Index from './Group/index';
import Members from './Group/Members';
import CatIndex from './Categories/CatIndex';
import Usercat from './Categories/Usercat';
import Categoryadd from './Categories/Categoryadd';
import Editprofile from './Users/Editprofile';
import Changepassword from './Users/Changepassword';
import Item from './Group/Item';

import Terms from './Terms';
import Privacy from './Privacy';
import Aboutus from './Aboutus';

import { Router, Route, Link } from 'react-router-dom';
import history from '../../history';

const WithHeader = (props) => {
	return(
	  <Layout type="loggedin" className="">
		{props.children}
	  </Layout>
	)
  }
  
  const WithHeaderDemo = (props) => {
	return(
	  <Layout type="guest" className="">
		{props.children}
	  </Layout>
	)
  }

  const WithMobileHeaderDemo = (props) => {
	return(
	  <Layout type="mobile" className="">
		{props.children}
	  </Layout>
	)
  }

  const access_token = localStorage.getItem("access_token")
  
export default class Approutes extends Component{
	


	render(){
		return (
			<div>
				<Router history={history}>
				<div>
					{(access_token!=null) ?
						<div>
							<Route exact path="/" component={() => <WithHeader>{<Dashboard/>}</WithHeader>} />
							<Route exact path="/users" component={() => <WithHeader>{<Usermanagement/>}</WithHeader>} />
							<Route exact path="/user" component={() => <WithHeader>{<Profile/>}</WithHeader>} />
							<Route exact path="/admin" component={() => <WithHeader>{<Profile/>}</WithHeader>} />
							<Route exact path="/closet" component={() => <WithHeader>{<Closet/>}</WithHeader>} />
							<Route exact path="/groups" component={() => <WithHeader>{<Index/>}</WithHeader>} />
							<Route exact path="/members" component={() => <WithHeader>{<Members/>}</WithHeader>} />	
							<Route exact path="/group_items" component={() => <WithHeader>{<Item/>}</WithHeader>} />	
							<Route exact path="/categories" component={() => <WithHeader>{<CatIndex/>}</WithHeader>} />		
							<Route exact path="/category_add" component={() => <WithHeader>{<Categoryadd/>}</WithHeader>} />		
							<Route exact path="/category" component={() => <WithHeader>{<Categoryadd/>}</WithHeader>} />	
							<Route exact path="/user_cat" component={() => <WithHeader>{<Usercat/>}</WithHeader>} />		
							<Route exact path="/edit" component={() => <WithHeader>{<Editprofile/>}</WithHeader>} />	
							<Route exact path="/change_password" component={() => <WithHeader>{<Changepassword/>}</WithHeader>} />						
						</div>
						:
						<div>
							
							<Route exact path="/about_us" component={() => <WithMobileHeaderDemo>{<Aboutus/>}</WithMobileHeaderDemo>} />
							<Route exact path="/privacy" component={() => <WithMobileHeaderDemo>{<Privacy/>}</WithMobileHeaderDemo>} />
							<Route exact path="/terms" component={() => <WithMobileHeaderDemo>{<Terms/>}</WithMobileHeaderDemo>} />
							<Route exact path="/" component={() => <WithHeaderDemo>{<Login/>}</WithHeaderDemo>} />
						
						</div>
					}
				</div>
				</Router>
				{/* <BrowserRouter>
					<Switch>
						<Route exact={true} path="/dashboard" component={() => <WithHeader>{<Dashboard/>}</WithHeader>} />
					
						<Route path="/" component={() => <WithHeaderDemo>{<Login/>}</WithHeaderDemo>} />
						<Route path="/register" component={Register} />
						<Route path="/dashboard" component={Dashboard} />
					</Switch>
				</BrowserRouter> */}
			</div>	
		);
	}
}


export function Apiheader() {
 
    // return authorization header with basic auth credentials
    let user = localStorage.getItem('access_token')

    if (user && user) {
        return {  'deviceType':'w','appversion' : '1.0','APPVERSION' : '1.0','authorization': user};
    } else {
        return {};
    }
}
import React, {Component} from 'react';
import {connect} from 'react-redux';
// import { Link } from 'react-router-dom';
import {fetchUser, deleteUser, suspend_user} from '../../actions/userActions';
import {fetchGroup, suspend_group} from '../../actions/groupActions';
import API_URL from "../../../Config"
import { MDBDataTableV5 } from 'mdbreact';
import SweetAlert from 'react-bootstrap-sweetalert';
import ImageViewer from 'react-simple-image-viewer';


let  mappedUser =[]
let  images =[]

let data_table = {}
export class Index extends Component{


    constructor(props) {
        super(props);
       
          this.checkbox1=""

          this.state = {
            table: {},
            are_you_sure: false,
            selectedId: "",
            deleted_success: false,
            suspend: true,
            selectedUser:{},
            isViewerOpen: false
          }
      
    }

    componentWillMount(){
        this.props.dispatch(fetchGroup());
    }
    showLogs2 = (e) => {
        this.checkbox1 = e
    }

    deleteUserdata = () => {
      this.setState({are_you_sure: false})
      this.props.dispatch(deleteUser(this.state.selectedId));
    }

    onCancel = () => {
      this.setState({are_you_sure: false, suspend: false}) 
    }

    deleteUser = () => {
        this.setState({are_you_sure: true})
    }

    setTable = (users) => {
       data_table = {    columns: [
        {
          label: 'Id',
          field: 'id',
          width: 150,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'ID',
          },
        },
        {
          label: 'Name',
          field: 'name',
          width: 150,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
          },
        },
        {
          label: 'Image',
          field: 'image',
          width: 270,
        },
       
          {
            label: 'Actions',
            name: "Action",
            field: 'action',
            sort: 'disabled',
            width: 100,
          },
        ],
      rows:[
          
      ]
        }
      mappedUser = []
   
      this.props.groups && this.props.groups.length>0 && this.props.groups.map((key, value)=>{
        
          let hsh = {}
          hsh["id"] = value+1
          hsh["name"] = key.name
          hsh["image"] = <div className="group-image"><a href={key.image.url!=null? API_URL.BASE_URL+key.image.url: ""}><img src={key.image.url!=null? API_URL.BASE_URL+key.image.url: ""}></img></a></div>
          hsh["action"] = <div>
                                 <ul className="navbar-nav">
                                   <li className="nav-item dropdown">
                                        <a className="nav-link" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="material-icons">dashboard</i>
                                       
                                        <p className="d-lg-none d-md-block">
                                        Stats
                                        </p>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                                        <a className="dropdown-item" onClick={() => {this.onSuspend(key); }}>{key.status==0?"Active":"Suspend"}</a>
                                        <a className="dropdown-item" href={"/members?id="+key.id}>View Members</a>
                                        <a className="dropdown-item" href={"/group_items?id="+key.id}>View Items</a>
                                        {/* <a className="dropdown-item" href="#">View Items</a> */}
                                        </div>
                                    </li>
                                    </ul>
                          </div>
                          

                mappedUser.push(hsh)
        })
  
        data_table["rows"]=mappedUser

        this.state.table = data_table
        // this.setState({table: data_table})

        return data_table
    }

    onSuspend = (user) => {
      this.setState({suspend: true, selectedUser: user}) 
      this.props.dispatch(suspend_group(user.id));
    }

	render(){

        const { groups, suspended } = this.props
        this.setTable(groups)
       
        console.log(data_table)

		return (
			
			// <div>
			// 	<ul>{mappedUser}</ul>
		
      // </div>
      <div className="row">

          {suspended &&
                  <SweetAlert
                    showCloseButton
                    title={ " "+ this.state.selectedUser.status==0 ? "Group activated Successfully" : "Group suspended Successfully" + ""}
                    onConfirm={() => {this.onCancel()}}
                    onCancel={(e) => {this.onCancel()}}
                    customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
                    show={suspended && this.state.suspend}
                    
                    />
                }
        

          {this.state.are_you_sure && 

          <SweetAlert
          warning
          showCancel
          title={"Are you sure?"}
          onConfirm={() => {this.deleteUserdata()}}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          confirmBtnText="Yes, delete it!"
          onCancel={(e) => {this.onCancel()}}
          customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
          show={this.state.are_you_sure}

          />
     
          
          }

   

         {   !this.state.are_you_sure &&  <div className="col-md-12">
                 <div class="card">
                <div class="card-header card-header-primary">
                  <h4 class="card-title ">Groups Management</h4>
                  <p class="card-category"> </p>
                </div>

                { groups && groups.length==0 &&
                    <div className="col-md-12">
                        <div className="card card-profile">
                            <div className="card-body">
                            <h4 className="card-title">No Group found </h4>
                            </div>
                        </div>
                    </div>
                }
                <div class="card-body">
            {data_table && data_table.rows && data_table.rows.length>0 &&
         

                <MDBDataTableV5
                  hover
                  entriesOptions={[10, 20, 25]}
                  entries={10}
                  pagesAmount={4}
                  data={data_table}
                  // checkbox
                  // headCheckboxID='id41'
                  // bodyCheckboxID='checkboxes41'
                  // getValueCheckboxes={(e) => {
                  // this.showLogs2(e);
                  // }}
                  // getValueAllCheckBoxes={(e) => {
                  // this.showLogs2(e);
                  // }}
                  // multipleCheckboxes
                  // proCheckboxes
                  // filledCheckboxes
                  // proSelect
                />
                }
                </div>
                </div>

          </div>

          
          }
         
         </div> 
		);
	}
}

const mapStateToProps = (store) => {
	return{
        groups:  store.group.group.groups,
        userFetched: store.group.fetched,
        setTable: e => {this.setTable(store.group.group.groups)},
        suspended: store.group.suspend,
	};
}
export default connect(mapStateToProps)(Index)
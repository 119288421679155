import React, {Component} from 'react';
import {connect} from 'react-redux';
// import { Link } from 'react-router-dom';
// import {fetchGroup, suspend_group} from '../../actions/groupActions';
import {fetchUserdata, updatePassword} from '../../actions/userActions'
import API_URL from "../../../Config"
import { MDBDataTableV5 } from 'mdbreact';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Category } from '@material-ui/icons';
let  mappedUser =[]
let data_table = {}

export class Changepassword extends Component{
    constructor(props) {
        super(props);
       
          this.checkbox1=""
          let query = new URLSearchParams(window.location.search);
          this.state = {
            table: {},
            are_you_sure: false,
            selectedId: 1,
            deleted_success: false,
            suspend: true,
            selectedUser:{
                old_password: "",
                new_password: "",
                confirm_password: ""
            },

           password_error: false,
           api_error: false,
          }
      
    }


    hangleChange = (event, type) => {

      if(type=="old_password"){
        var matches = event.target.value.match( /^\s+$/);
        if (matches== null ) {
          this.setState({selectedUser:{old_password: event.target.value, new_password: this.state.selectedUser.new_password, confirm_password: this.state.selectedUser.confirm_password}})
        }
      }else if(type=="new_password"){

        var matches = event.target.value.match( /^\s+$/);
        if (matches== null ) {
          this.setState({selectedUser:{old_password: this.state.selectedUser.old_password, new_password: event.target.value, confirm_password: this.state.selectedUser.confirm_password}})
        }

      }else if(type=="confirm_password"){

        var matches = event.target.value.match( /^\s+$/);
        if (matches== null ) {
          this.setState({selectedUser:{old_password: this.state.selectedUser.old_password, new_password: this.state.selectedUser.new_password, confirm_password: event.target.value}})
        }

      }
     
    }


    onCancel = () => {
      this.setState({are_you_sure: false, suspend: false,password_error:false}) 
    //   window.location.href = "/"
    }

    formSubmit = (e) => {
      e.preventDefault()
      

      if(this.state.selectedUser.new_password != this.state.selectedUser.confirm_password){
        this.setState({...this.state,password_error: true})

      }else{
        this.setState({...this.state,password_error: false})
                
        let hsh =   {
                    "user":{
                        "old_password": this.state.selectedUser.old_password,
                        "new_password": this.state.selectedUser.new_password
                        }
                    }

        this.props.dispatch(updatePassword(hsh));

      }

      
    }

    
	render(){

        const { users, userFetched } = this.props


		return (
      <div className="row">

                {userFetched &&
                  <SweetAlert
                    showCloseButton
                    title={ "Password changed successfully."}
                    onConfirm={() => {this.onCancel()}}
                    onCancel={(e) => {this.onCancel()}}
                    customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
                    show={userFetched && this.state.suspend}
                    
                    />
                }

        {this.state.password_error &&
                  <SweetAlert
                    showCloseButton
                    title={ "New and confirm password not matched"}
                    onConfirm={() => {this.onCancel()}}
                    onCancel={(e) => {this.onCancel()}}
                    customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
                    show={ this.state.password_error}
                    
                    />
                }
          <div className="col-md-2"></div>
        
                <div className="col-md-8">
                    <div className="card">
                    <div className="card-header card-header-primary">
                        <h4 className="card-title">Change Password</h4>
                        <p className="card-category"></p>
                    </div>
                    <div className="card-body">
                        <form onSubmit={(e)=>{this.formSubmit(e)}}>
                    
                        <div className="row" >
                            <div className="col-md-12">
                            <div className="form-group">
                                <label className="">Old Password</label>
                                <input type="password" className="form-control" value={this.state.selectedUser.old_password} onChange={(e)=>{this.hangleChange(e, "old_password")}} minLength={8} required/>
                            </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                            <div className="form-group">
                                <label className="">New Password</label>
                                <input type="password" className="form-control" value={this.state.selectedUser.new_password} onChange={(e)=>{this.hangleChange(e, "new_password")}} minLength={8} required/>
                            </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                            <div className="form-group">
                            
                                <div className="form-group">
                                <label>Confirm Password</label>
                                <input type="text" className="form-control" value={this.state.selectedUser.confirm_password} onChange={(e)=>{this.hangleChange(e, "confirm_password")}} minLength={8} required/>

                                
                                </div>
                            </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary pull-center">Update</button>
                        <div className="clearfix"></div>
                        </form>
                        </div>
                        </div>
                        </div>
            
            <div className="col-md-2"></div>
            </div>
		);
	}
}

const mapStateToProps = (store) => {
	return{
        users: store.user.user.user,
        userFetched: store.user.api_response,
        setTable: e => {this.setTable(store.category.category.categories)},
        suspended: store.category.suspend,
	};
}
export default connect(mapStateToProps)(Changepassword)
import React, {Component} from 'react';

export class Terms extends Component{
	render() {
	    return(
	    	<div className="center-div">
	    
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="text-center">
                                <h1 className="heading">Terms and Conditions</h1>
                            </div>
                            
                        </div>
                        <div className="col-12 privacy">
                        
                                <p className="content"> These terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you") and THRIFTERZ, LLC, concerning your access to and use of the www.THRIFTERZ.CLUB website as well as any other media form, mobile website or mobile applications related, linked, or otherwise connected thereto (collectively, the "Site").You agree that by accessing the Site, you have read, understood, and agree to be bound by all these Terms of Use. If you do not agree with all of these Terms of Use, then you are expressly prohibited from using the Site and you must discontinue use immediately.</p>

                                <p className=""> Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason. We will alert you about any changes by updating the "Last updated" date of these Terms of Use, and you waive any right to receive specific notice of each such change. </p>
                        </div>
                    </div>
                </div>
	        
	        </div>
	    )
  	}
}
export default (Terms);
import axios from 'axios';
import API_URL from "../../Config"
import {Apiheader} from "../helpers/Apiheader"
import history from '../../history';

var configheaders = {
    headers: Apiheader()
}

export function fetchGroup(){
	return function(dispatch){
	axios.get(API_URL.API_PATH+'/admins/groups', configheaders)
	 	.then((response)=>{
			dispatch ({type: 'FETCH_GROUP_FULLFILLED',payload:response.data})
		}).catch(function(error){
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}

export function fetchGroupitems(groupId){
	return function(dispatch){
	axios.get(API_URL.API_PATH+'/admins/group_items_by_admin/'+groupId, configheaders)
	 	.then((response)=>{
			dispatch ({type: 'FETCH_GROUP_ITEM_FULLFILLED',payload:response.data})
		}).catch(function(error){
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}

export function suspend_group(groupId){
	return function(dispatch){
	axios.get(API_URL.API_PATH+'/admins/suspend_group/'+groupId, configheaders)
	 	.then((response)=>{
			dispatch ({type: 'SUSPEND_GROUP_FULLFILLED',payload:response.data})
		}).catch(function(error){
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}

export function members(groupId){
	return function(dispatch){
	axios.get(API_URL.API_PATH+'/admins/group_members/'+groupId, configheaders)
	 	.then((response)=>{
			dispatch ({type: 'FETCH_GROUP_MEMBER_FULLFILLED',payload:response.data})
		}).catch(function(error){
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}
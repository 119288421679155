import React, {Component} from 'react';
import {connect} from 'react-redux';
// import { Link } from 'react-router-dom';
import {dashboard, filteritems} from '../actions/userActions';
import { Bar } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";
var jstz = require('jstz');

export class Dashboard extends Component{

  constructor(props) {
    super(props);
   
      this.checkbox1=""

  
  }


    componentWillMount(){
      var tz =   jstz.determine();

      this.props.dispatch(dashboard(tz.name()));
    }
    handleOnchange = (event) => {
      var tz =   jstz.determine();
      var filtertype = "bla bla" ;
      filtertype = event.target.value
      let hsh = {"tz": tz.name(),
        "filtertype": filtertype}
    

      // console.log("type---->", filtertype)
      this.props.dispatch(filteritems(hsh))
     
    }
	render(){
    const { users , graphValues} = this.props
    let graph_data = {}
  if (graphValues){
    graph_data =  {  
      dataBar: {
        labels: graphValues.labels,
        datasets: [
          {
            label: graphValues.label,
            data: graphValues.data,
            backgroundColor: [
              "rgba(255, 134,159,0.4)",
              "rgba(98,  182, 239,0.4)",
              "rgba(255, 218, 128,0.4)",
              "rgba(113, 205, 205,0.4)",
              "rgba(170, 128, 252,0.4)",
              "rgba(255, 177, 101,0.4)",
              "rgba(255, 134,159,0.4)",
              "rgba(98,  182, 239,0.4)",
              "rgba(255, 218, 128,0.4)",
              "rgba(113, 205, 205,0.4)",
              "rgba(170, 128, 252,0.4)",
              "rgba(255, 177, 101,0.4)",
              "rgba(255, 134,159,0.4)",
              "rgba(98,  182, 239,0.4)",
              "rgba(255, 218, 128,0.4)",
              "rgba(113, 205, 205,0.4)",
              "rgba(170, 128, 252,0.4)",
              "rgba(255, 177, 101,0.4)",
              "rgba(255, 134,159,0.4)",
              "rgba(98,  182, 239,0.4)",
              "rgba(255, 218, 128,0.4)",
              "rgba(113, 205, 205,0.4)",
              "rgba(170, 128, 252,0.4)",
              "rgba(255, 177, 101,0.4)",
              "rgba(255, 134,159,0.4)",
              "rgba(98,  182, 239,0.4)",
              "rgba(255, 218, 128,0.4)",
              "rgba(113, 205, 205,0.4)",
              "rgba(170, 128, 252,0.4)",
              "rgba(255, 177, 101,0.4)",
              "rgba(255, 134,159,0.4)",
              "rgba(98,  182, 239,0.4)",
              "rgba(255, 218, 128,0.4)",
              "rgba(113, 205, 205,0.4)",
              "rgba(170, 128, 252,0.4)",
              "rgba(255, 177, 101,0.4)",
            ],
            borderWidth: 2,
            borderColor: [
              "rgba(255, 134, 159, 1)",
              "rgba(98,  182, 239, 1)",
              "rgba(255, 218, 128, 1)",
              "rgba(113, 205, 205, 1)",
              "rgba(170, 128, 252, 1)",
              "rgba(255, 177, 101, 1)",
              "rgba(255, 134, 159, 1)",
              "rgba(98,  182, 239, 1)",
              "rgba(255, 218, 128, 1)",
              "rgba(113, 205, 205, 1)",
              "rgba(170, 128, 252, 1)",
              "rgba(255, 177, 101, 1)",
              "rgba(255, 134, 159, 1)",
              "rgba(98,  182, 239, 1)",
              "rgba(255, 218, 128, 1)",
              "rgba(113, 205, 205, 1)",
              "rgba(170, 128, 252, 1)",
              "rgba(255, 177, 101, 1)",
              "rgba(255, 134, 159, 1)",
              "rgba(98,  182, 239, 1)",
              "rgba(255, 218, 128, 1)",
              "rgba(113, 205, 205, 1)",
              "rgba(170, 128, 252, 1)",
              "rgba(255, 177, 101, 1)",
              "rgba(255, 134, 159, 1)",
              "rgba(98,  182, 239, 1)",
              "rgba(255, 218, 128, 1)",
              "rgba(113, 205, 205, 1)",
              "rgba(170, 128, 252, 1)",
              "rgba(255, 177, 101, 1)",
              "rgba(255, 134, 159, 1)",
              "rgba(98,  182, 239, 1)",
              "rgba(255, 218, 128, 1)",
              "rgba(113, 205, 205, 1)",
              "rgba(170, 128, 252, 1)",
              "rgba(255, 177, 101, 1)",
            ]
          }
        ]
      },
      barChartOptions: {
        responsive: true,
        // maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              barPercentage: 1,
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)"
              }
            }
          ],
          yAxes: [
            {
              barPercentage: 1,
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)"
              },
              ticks: {
                beginAtZero: false
              }
            }
          ]
        }
      }
    }

  } 
   
    

		// let mappedUser=[];

		// mappedUser = users.data && users.data.map(user=> <li>{user.name}</li>)

		return (
			
			// <div>
			// 	<ul>{mappedUser}</ul>
		
			// </div>
			<div>
        {users &&
			<div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card card-stats">
                <div className="card-header card-header-warning card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">content_copy</i>
                  </div>
                  <p className="card-category">Registered Users</p>
                  <h3 className="card-title">{users.total_users}
                    {/* <small>GB</small> */}
                  </h3>
                </div>
                <div className="card-footer">
                  {/* <div className="stats">
                    <i className="material-icons text-danger">warning</i>
                    <a href="javascript:;">Get More Space...</a>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card card-stats">
                <div className="card-header card-header-success card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">store</i>
                  </div>
                  <p className="card-category">Items added for Giveaways</p>
                  <h3 className="card-title">{users.item_added_for_giveaways}</h3>
                </div>
                <div className="card-footer">
                  {/* <div className="stats">
                    <i className="material-icons">date_range</i> Last 24 Hours
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card card-stats">
                <div className="card-header card-header-danger card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">info_outline</i>
                  </div>
                  <p className="card-category">Items added for Shares</p>
                  <h3 className="card-title">{users.item_added_for_share}</h3>
                </div>
                <div className="card-footer">
                  {/* <div className="stats">
                    <i className="material-icons">local_offer</i> Tracked from Github
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card card-stats">
                <div className="card-header card-header-info card-header-icon">
                  <div className="card-icon">
                    <i className="fa fa-twitter"></i>
                  </div>
                  <p className="card-category">Items added for Trade</p>
                  <h3 className="card-title">{users.item_added_for_trade}</h3>
                </div>
                <div className="card-footer">
                  {/* <div className="stats">
                    <i className="material-icons">update</i> Just Updated
                  </div> */}
                </div>
              </div>
            </div>
          </div>}
          {users &&
<div className="row">
<div className="col-lg-3 col-md-6 col-sm-6">
  <div className="card card-stats">
	<div className="card-header card-header-warning card-header-icon">
	  <div className="card-icon">
		<i className="material-icons">content_copy</i>
	  </div>
	  <p className="card-category">Items added for Sale</p>
	  <h3 className="card-title">{users.item_added_for_sale}
		{/* <small>GB</small> */}
	  </h3>
	</div>
	<div className="card-footer">
	  {/* <div className="stats">
		<i className="material-icons text-danger">warning</i>
		<a href="javascript:;">Get More Space...</a>
	  </div> */}
	</div>
  </div>
</div>
<div className="col-lg-3 col-md-6 col-sm-6">
  <div className="card card-stats">
	<div className="card-header card-header-success card-header-icon">
	  <div className="card-icon">
		<i className="material-icons">store</i>
	  </div>
	  <p className="card-category">Items shared for Giveaways</p>
	  <h3 className="card-title">{users.item_shared_for_giveaways}</h3>
	</div>
	<div className="card-footer">
	  {/* <div className="stats">
		<i className="material-icons">date_range</i> Last 24 Hours
	  </div> */}
	</div>
  </div>
</div>
<div className="col-lg-3 col-md-6 col-sm-6">
  <div className="card card-stats">
	<div className="card-header card-header-danger card-header-icon">
	  <div className="card-icon">
		<i className="material-icons">info_outline</i>
	  </div>
	  <p className="card-category">Items shared for Shares</p>
	  <h3 className="card-title">{users.item_shared_for_share}</h3>
	</div>
	<div className="card-footer">
	  {/* <div className="stats">
		<i className="material-icons">local_offer</i> Tracked from Github
	  </div> */}
	</div>
  </div>
</div>
<div className="col-lg-3 col-md-6 col-sm-6">
  <div className="card card-stats">
	<div className="card-header card-header-info card-header-icon">
	  <div className="card-icon">
		<i className="fa fa-twitter"></i>
	  </div>
	  <p className="card-category">Items shared for Trade</p>
	  <h3 className="card-title">{users.item_shared_for_trade}</h3>
	</div>
	<div className="card-footer">
	  {/* <div className="stats">
		<i className="material-icons">update</i> Just Updated
	  </div> */}
	</div>
  </div>
</div>

</div>}
    {users && <div className="row">
    <div className="col-lg-3 col-md-6 col-sm-6">
      <div className="card card-stats">
      <div className="card-header card-header-warning card-header-icon">
        <div className="card-icon">
        <i className="material-icons">content_copy</i>
        </div>
        <p className="card-category">Items shared for Sale</p>
        <h3 className="card-title">{users.item_shared_for_sale}
        {/* <small>GB</small> */}
        </h3>
      </div>
      <div className="card-footer">
        {/* <div className="stats">
        <i className="material-icons text-danger">warning</i>
        <a href="javascript:;">Get More Space...</a>
        </div> */}
      </div>
      </div>
    </div>
    </div>}
    {users && <div className="row">
      
    <MDBContainer>
        <h3 className="mt-5">Select Filter For Items</h3>
        <div className="select_filter">
          <select  className="form-control"  onChange={(e)=>{this.handleOnchange(e)}}>
            <option value="0">Today</option>
            <option value="1">This week</option>
            <option value="2">This month</option>
            <option value="3">Last 3 months</option>
            <option value="4">Last 6 months</option>
            <option value="5">Last 1 year</option>
          </select>
          <i class="fas fa-caret-down"></i>
          </div>
 
      </MDBContainer>
      </div>}
    {users && <div className="row">
      <MDBContainer>
        <h3 className="mt-5">Items chart</h3>
       {graph_data && <Bar data={graph_data.dataBar} options={graph_data.barChartOptions} />}
      </MDBContainer>
      </div>}
		</div>	
		);
	}
}

const mapStateToProps = (store) => {
  
	return{
		users: store.user.user,
    userFetched: store.user.fetched,
    graphValues: store.user.garph
	};
}

export default connect(mapStateToProps)(Dashboard)
import {combineReducers} from 'redux';
import user from './userReducer';
import group from './groupReducer';
import category from './categoryReducer';
import login from './loginReducer';

export default combineReducers({
	user, 
	login,
	group,
	category
})
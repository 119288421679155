const initialState={
	fetching:false,
	fetched:false,
	delete: false,
	userdelete: false,
    suspend: false,
    admin: {},
	group:[]
}
export default function reducer(state=initialState, action){

    
	switch(action.type){
		case 'FETCH_GROUP':{
			return{...state,fetching:true}
			break;
		}
		case 'FETCH_GROUP_FULLFILLED':{
            console.log(action.payload)
			return{...state, fetching:false, fetched:true, group:action.payload, delete: false, userdelete: false, suspend: false}
			break;
		}
		case 'FETCH_GROUP_MEMBER_FULLFILLED':{
            console.log(action.payload)
			return{...state, fetching:false, fetched:true, group:action.payload, admin: action.payload.admin,  delete: false, userdelete: false, suspend: false}
			break;
		}
		case 'FETCH_USER_ClOSET_DATA_FULLFILLED':{
			return{...state, fetching:false, fetched:true, group:action.payload, delete: false, userdelete: false, suspend: false}
			break;
		}
		case 'DELETE_USER_ClOSET_DATA_FULLFILLED':{
			return{...state, fetching:false, fetched:true, group:action.payload, delete: true, userdelete: false, suspend: false}
			break;
		}
		case 'DELETE_USER_FULLFILLED':{
			return{...state, fetching:false, fetched:true, group:action.payload, delete: false, userdelete: true, suspend: false}
			break;
		}
		case 'SUSPEND_GROUP_FULLFILLED':{
			return{...state, fetching:false, fetched:true, group:action.payload, delete: false, userdelete: false, suspend: true}
			break;
		}
		case 'FETCH_GROUP_ITEM_FULLFILLED':{
			
			return{...state, fetching:false, fetched:true, group:action.payload, delete: false, userdelete: false, suspend: false}
			break;
		}
	}
	return state;
}

import React from 'react'



const Sidebar = () => {
	
    const pathn = window.location.pathname.split("?")
    const pathname = pathn[0]


	// const [cartcount, setcartcount] = useState(userInfo.count);

    return (
                <div className="sidebar" data-color="purple" data-background-color="white" data-image="../assets/img/sidebar-1.jpg">

                    <div className="logo"><a href="/" className="simple-text logo-normal">
                      Thrifterz Admin
                        </a></div>
                    <div className="sidebar-wrapper">
                        <ul className="nav">
                        <li className={(pathname== "/")||(pathname== "/")? "nav-item active ":"nav-item "}>
                            <a className="nav-link" href="/">
                            <i className="material-icons">dashboard</i>
                            <p>Dashboard</p>
                            </a>
                        </li>
                        <li className={(pathname== "/users")||(pathname== "/user")|| (pathname== "/closet")? "nav-item active ":"nav-item "}>
                            <a className="nav-link" href="/users">
                            <i className="material-icons">person</i>
                            <p>User Management</p>
                            </a>
                        </li>
                        <li className={(pathname== "/groups")||(pathname== "/members")|| (pathname== "/df")? "nav-item active ":"nav-item "}>
                            <a className="nav-link" href="/groups">
                            <i className="material-icons">groups</i>
                            <p>Group Management</p>
                            </a>
                        </li>
                        <li className={(pathname== "/categories")||(pathname== "/category_add")|| (pathname== "/df")? "nav-item active ":"nav-item "}>
                            <a className="nav-link" href="/categories">
                            <i className="material-icons">category</i>
                            <p>Categories Management</p>
                            </a>
                        </li>
               
                        </ul>
                    </div>
                    </div>

)
}

export default Sidebar
import React, {Component} from 'react';
import {connect} from 'react-redux';
// import { Link } from 'react-router-dom';
import {fetchUser, deleteUser, suspend_user} from '../actions/userActions';
import { MDBDataTableV5 } from 'mdbreact';
import SweetAlert from 'react-bootstrap-sweetalert';
let  mappedUser =[]
let data_table = {}
export class Usermanagement extends Component{


    constructor(props) {
        super(props);
       
          this.checkbox1=""

          this.state = {
            table: {},
            are_you_sure: false,
            selectedId: "",
            deleted_success: false,
            suspend: true,
            selectedUser:{}
          }
      
      }

	  componentWillMount(){
        this.props.dispatch(fetchUser());
    }
    showLogs2 = (e) => {
        this.checkbox1 = e
    }

    deleteUserdata = () => {
      this.setState({are_you_sure: false})
      this.props.dispatch(deleteUser(this.state.selectedId));
    }

    onCancel = () => {
      this.setState({are_you_sure: false, suspend: false}) 
    }

    deleteUser = () => {
        this.setState({are_you_sure: true})
    }

    setTable = (users) => {
       data_table = {    columns: [
        {
          label: 'Id',
          field: 'id',
          width: 150,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'ID',
          },
        },
        {
          label: 'Name',
          field: 'name',
          width: 150,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
          },
        },
        {
          label: 'Phone Number',
          field: 'phone_number',
          width: 270,
        },
        {
          label: 'City',
          field: 'city',
          width: 270,
        },
          {
            label: 'Actions',
            name: "Action",
            field: 'action',
            sort: 'disabled',
            width: 100,
          },
        ],
      rows:[
          
      ]
        }
      mappedUser = []
   
      this.props.users && this.props.users.length>0 && this.props.users.map((key, value)=>{
          let hsh = {}
          hsh["id"] = value+1
          hsh["name"] = key.first_name+" "+key.last_name
          hsh["phone_number"] = "+ "+key.country_code + " "+key.phone_number
          hsh["city"] = key.city
          hsh["action"] = <div>
                              <a  onClick={() => console.log("hi")}>
                                <span _ngcontent-ghw-c19="" onClick={() => {this.onSuspend(key); }} class="material-icons icon-image-preview">{key.status==0 ? "toggle_on": "toggle_off"}</span>  
                              </a> 
                              {/* <a  onClick={() => {this.setState({are_you_sure: true, selectedId: key.id})}}>
                                <span _ngcontent-kgf-c19="" class="material-icons icon-image-preview">delete</span> 
                              </a> */}
                              <a href={'/user?id='+key.id}>  
                                <span _ngcontent-kgf-c19="" class="material-icons icon-image-preview">remove_red_eye</span>
                              </a>
                          </div>

          mappedUser.push(hsh)
        })
  
        data_table["rows"]=mappedUser

        this.state.table = data_table
        // this.setState({table: data_table})

        return data_table
    }

    onSuspend = (user) => {
      console.log(user)
      this.setState({suspend: true, selectedUser: user}) 
      this.props.dispatch(suspend_user(user.id));
    }

	render(){

        const { users, suspended } = this.props
        this.setTable(users)
       
       

		return (
			
			// <div>
			// 	<ul>{mappedUser}</ul>
		
      // </div>
      <div className="row">

          {suspended &&
                  <SweetAlert
                    showCloseButton
                    title={this.props.message}
                    onConfirm={() => {this.onCancel()}}
                    onCancel={(e) => {this.onCancel()}}
                    customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
                    show={suspended && this.state.suspend}
                    
                    />
                }
        

          {this.state.are_you_sure && 

          <SweetAlert
          warning
          showCancel
          title={"Are you sure?"}
          onConfirm={() => {this.deleteUserdata()}}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          confirmBtnText="Yes, delete it!"
          onCancel={(e) => {this.onCancel()}}
          customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
          show={this.state.are_you_sure}

          />
     
          
          }

   

         {   !this.state.are_you_sure &&  <div className="col-md-12">
                 <div class="card">
                <div class="card-header card-header-primary">
                  <h4 class="card-title " >User Management</h4>
                  <p class="card-category"> </p>
                </div>

                { users && users.length==0 &&
                    <div className="col-md-12">
                        <div className="card card-profile">
                            <div className="card-body">
                            <h4 className="card-title">No User found </h4>
                            </div>
                        </div>
                    </div>
                }
                <div class="card-body">
            {data_table && data_table.rows && data_table.rows.length>0 &&
         

                <MDBDataTableV5
                  hover
                  entriesOptions={[10, 20, 25]}
                  entries={10}
                  pagesAmount={4}
                  data={data_table}
                  // checkbox
                  // headCheckboxID='id41'
                  // bodyCheckboxID='checkboxes41'
                  // getValueCheckboxes={(e) => {
                  // this.showLogs2(e);
                  // }}
                  // getValueAllCheckBoxes={(e) => {
                  // this.showLogs2(e);
                  // }}
                  // multipleCheckboxes
                  // proCheckboxes
                  // filledCheckboxes
                  // proSelect
                />
                }
                </div>
                </div>

          </div>
          }
         </div> 
		);
	}
}

const mapStateToProps = (store) => {

	return{
        users:  store.user.user.users,
        userFetched: store.user.fetched,
        setTable: e => {this.setTable(store.user.user.users)},
        suspended: store.user.suspend,
        message: store.user.user.message
	};
}
export default connect(mapStateToProps)(Usermanagement)
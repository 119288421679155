import React, {Component} from 'react';

export class Aboutus extends Component{
	render() {
	    return(
	    	<div className="center-div">

<div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="text-center">
                                <h1 className="heading">About Us</h1>
                            </div>
                            
                        </div>
                        <div className="col-sm-6 privacy">
                            <img src="https://img1.wsimg.com/isteam/stock/u37JlEKQYBcRRNrrg/:/rs=w:365,h:365,cg:true,m/cr=w:365,h:365"></img>
                            <h4>Share Your Styles</h4>
                            <p className="content"> Create friend groups and borrow/trade your clothes, accessories or books with them! Keep track who borrowed your item, send reminders when the item is due to be returned, or offer to trade it for another item. </p>
                        </div>
                        <div className="col-sm-6 privacy">
                        <img src="https://img1.wsimg.com/isteam/stock/u37JlEKQYBcRRNrrg/:/rs=w:365,h:365,cg:true,m/cr=w:365,h:365"></img>
                            <h4>Your Closet Catalogue</h4>
                            <p className="content"> Thrifterz App also allows you to catalogue your closet - never forget that shirt that goes with your favorite jeans, or wonder if you have anything to go with the new pair of shoes you are considering buying. Efficiently stylish!
                            </p>
                        </div>
                    </div>
                </div>
	    
            
	        
	        </div>
	    )
  	}
}
export default (Aboutus);
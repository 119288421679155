import axios from 'axios';
import API_URL from "../../Config"
import {Apiheader} from "../helpers/Apiheader"
import history from '../../history';

var configheaders = {
    headers: Apiheader()
}

export function fetchUser(){
	return function(dispatch){
	axios.get(API_URL.API_PATH+'/admins/get_all_users', configheaders)
	 	.then((response)=>{
			dispatch ({type: 'FETCH_USER_FULLFILLED',payload:response.data})
		}).catch(function(error){
			localStorage.clear()
			window.location.href="/"
		})
	}
}


export function fetchUserdata(userId){
	return function(dispatch){
	axios.get(API_URL.API_PATH+'/admins/get_user/'+userId, configheaders)
	 	.then((response)=>{
			dispatch ({type: 'FETCH_USER_DATA_FULLFILLED',payload:response.data})
		}).catch(function(error){
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}

export function fetchUserCloset(userId){
	return function(dispatch){
	axios.get(API_URL.API_PATH+'/admins/get_user_closet/'+userId, configheaders)
	 	.then((response)=>{
			dispatch ({type: 'FETCH_USER_ClOSET_DATA_FULLFILLED',payload:response.data})
		}).catch(function(error){
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}

export function deleteUser(userId){

	return function(dispatch){
	axios.delete(API_URL.API_PATH+'/admins/delete_user/'+userId, configheaders)
	 	.then((response)=>{
			dispatch ({type: 'DELETE_USER_FULLFILLED',payload:response.data})
		}).catch(function(error){
	
		})
	}
}

export function deleteUserClosetItem(userId, ItemId){
	let params={
		is_from_closet: 1,
		user_id: userId,
		item_id: ItemId
	}
	return function(dispatch){
	axios.post(API_URL.API_PATH+'/admins/delete_item_from_user_closet', params, configheaders)
	 	.then((response)=>{
			dispatch ({type: 'DELETE_USER_ClOSET_DATA_FULLFILLED',payload:response.data})
		}).catch(function(error){
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}

export function suspend_user(userId){
	console.log("user_id---------->", userId)
	return function(dispatch){
	axios.get(API_URL.API_PATH+'/admins/suspend_user/'+userId, configheaders)
	 	.then((response)=>{
			dispatch ({type: 'SUSPEND_USER_FULLFILLED',payload:response.data})
		}).catch(function(error){
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}

export function updateProfile(formData){

	// let params = formData
	return function(dispatch){
	axios.put(API_URL.API_PATH+'/auth/create_profile', formData ,configheaders)
	 	.then((response)=>{
			dispatch ({type: 'UPDATE_USER_FULLFILLED',payload:response.data})
		}).catch(function(error){
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}

export function updatePassword(params){

	// let params = formData
	return function(dispatch){
	axios.put(API_URL.API_PATH+'/auth/update_password', params ,configheaders)
	 	.then((response)=>{
			dispatch ({type: 'UPDATE_USER_PASSWORD_FULLFILLED',payload:response.data})
		}).catch(function(error){
			if(error.response){
				
			alert(error.response.data.error.message)
			}
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}

export function dashboard(time_zone){

	// let params = formData
	return function(dispatch){
	axios.get(API_URL.API_PATH+'/admins/dashboard?timezone='+time_zone ,configheaders)
	 	.then((response)=>{
			dispatch ({type: 'DASHBOARD_FULLFILLED',payload:response.data})
		}).catch(function(error){
			errorHandling(error)
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}

export function filteritems(data){
	
	// let params = formData
	return function(dispatch){
	axios.get(API_URL.API_PATH+'/admins/filter_graph?timezone='+data.tz+'&&type='+data.filtertype ,configheaders)
	 	.then((response)=>{
			dispatch ({type: 'FILTER_FULLFILLED',payload:response.data})
		}).catch(function(error){
		
			
		})
	}
}


const errorHandling = (error)=>{

	if(error.request.status!=401 && error.response){	
		alert(error.response.data.error.message)
	}

	if(error.request.status==401){
		alert("Your Session has been expired. Please try again later.")
		 localStorage.clear()
		 window.location.href="/"
	}

}





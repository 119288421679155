import React, {Component} from 'react';
import {connect} from 'react-redux';
// import { Link } from 'react-router-dom';
// import {fetchGroup, suspend_group} from '../../actions/groupActions';
import {fetchUserdata, updateProfile} from '../../actions/userActions'
import API_URL from "../../../Config"
import { MDBDataTableV5 } from 'mdbreact';
import SweetAlert from 'react-bootstrap-sweetalert';

import { Category } from '@material-ui/icons';
let  mappedUser =[]
let data_table = {}

export class Editprofile extends Component{
    constructor(props) {
        super(props);
       
          this.checkbox1=""
          let query = new URLSearchParams(window.location.search);
          this.state = {
            table: {},
            are_you_sure: false,
            selectedId: 1,
            deleted_success: false,
            suspend: true,
            selectedUser:{
              first_name: "",
              last_name: "",
              city: "",
              about_me: "",
              email: ""
            },
            imagedata: {},
            image: ""
          }
      
    }

    componentWillMount(){
      this.props.dispatch(fetchUserdata(this.state.selectedId));
    }

    componentDidMount() {
      setTimeout(() => {
        console.log("props------------------------->")
       console.log(this.props.users)
     
       if(this.props.users){
        this.setState({
          selectedUser:{
           first_name: this.props.users.first_name,
           last_name: this.props.users.last_name,
           city: this.props.users.city,
           about_me: this.props.users.about_me,
           email: this.props.users.email,
          }
        })
       }
     
      }, 1000)
    }

    hangleChange = (event, type) => {

      if(type=="first_name"){
        var matches = event.target.value.match( /^\s+$/);
        if (matches== null ) {
          this.setState({selectedUser:{first_name: event.target.value, last_name: this.state.selectedUser.last_name, city: this.state.selectedUser.city,
          about_me: this.state.selectedUser.about_me}})
        }
      }else if(type=="last_name"){

        var matches = event.target.value.match( /^\s+$/);
        if (matches== null ) {
          this.setState({selectedUser:{last_name: event.target.value, first_name: this.state.selectedUser.first_name, city: this.state.selectedUser.city,
            about_me: this.state.selectedUser.about_me}})
        }

      }else if(type=="city"){

        var matches = event.target.value.match( /^\s+$/);
        if (matches== null ) {
          this.setState({selectedUser:{city: event.target.value, first_name: this.state.selectedUser.first_name, last_name: this.state.selectedUser.last_name,about_me: this.state.selectedUser.about_me}})
        }

      }else if(type=="about_me"){

        var matches = event.target.value.match( /^\s+$/);
        if (matches== null ) {
          this.setState({selectedUser:{about_me: event.target.value, first_name: this.state.selectedUser.first_name, last_name: this.state.selectedUser.last_name,city: this.state.selectedUser.city}})
        }

      }
     
    }


    onCancel = () => {
      this.setState({are_you_sure: false, suspend: false}) 
      window.location.href = "/"
    }


     handleImageChange=(e)=> {
        e.preventDefault();
        
 
        let file = e.target.files[0];
        // setImagedata(file)
        this.setState({ imagedata: file})
        let reader = new FileReader();
        var url1 = reader.readAsDataURL(file);
      
  
        reader.onloadend = () => {
        
        
          const csv = reader.result ;
        
       
          this.setState({ image: csv})
       
  
        }
      }

    formSubmit = (e) => {
      e.preventDefault()
        
      const formdata = new FormData()
      formdata.append("user[first_name]",this.state.selectedUser.first_name)
      formdata.append("user[last_name]",this.state.selectedUser.last_name)
      formdata.append("user[city]",this.state.selectedUser.city)
      formdata.append("user[about_me]",this.state.selectedUser.about_me)
      formdata.append("user[image]",this.state.imagedata)

   
        this.props.dispatch(updateProfile(formdata));
      
    }

    
	render(){

        const { users, userFetched } = this.props

    
		return (
      <div className="row">

                {userFetched &&
                  <SweetAlert
                    showCloseButton
                    title={ "Profile updated successfully."}
                    onConfirm={() => {this.onCancel()}}
                    onCancel={(e) => {this.onCancel()}}
                    customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
                    show={userFetched && this.state.suspend}
                    
                    />
                }
          <div className="col-md-2"></div>
          {users &&
      <div className="col-md-8">
        <div className="card">
          <div className="card-header card-header-primary">
            <h4 className="card-title">Edit Profile</h4>
            <p className="card-category">Complete your profile</p>
          </div>
          <div className="card-body">
            <form onSubmit={(e)=>{this.formSubmit(e)}}>
            <div className="card card-profile">
                <div className="card-avatar">
                  <a href="javascript:;">
                   
                    <img className="img" src={this.state.image!=""? this.state.image :users && users.image &&  (users.image.url== null) ? "../assets/img/faces/marc.jpg":API_URL.BASE_URL+"/"+ users.image.url} />
                  </a>
                </div>
                <input type="file" onChange={(e)=>this.handleImageChange(e)}></input>
              </div>
            <div className="row" >
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="">Email</label>
                    <input type="email" className="form-control" value={users.email} disabled/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="">First Name</label>
                    <input type="text" className="form-control" value={this.state.selectedUser.first_name} onChange={(e)=>{this.hangleChange(e, "first_name")}}/>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="">Last Name</label>
                    <input type="text" className="form-control" value={this.state.selectedUser.last_name} onChange={(e)=>{this.hangleChange(e, "last_name")}}/>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                  <label className="">City</label>
                    <input type="text" className="form-control" value={this.state.selectedUser.city} onChange={(e)=>{this.hangleChange(e, "city")}}/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>About Me</label>
                    <div className="form-group">
                      {/* <label className="bmd-label-floating"> Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo.</label> */}
                      <textarea className="form-control" onChange={(e)=>{this.hangleChange(e, "about_me")}} value={this.state.selectedUser.about_me} rows="5">{this.state.selectedUser.about_me}</textarea>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary pull-center">Submit</button>
              <div className="clearfix"></div>
            </form>
            </div>
            </div>
            </div>
            }
            <div className="col-md-2"></div>
            </div>
		);
	}
}

const mapStateToProps = (store) => {
	return{
        users: store.user.user.user,
        userFetched: store.user.api_response,
        setTable: e => {this.setTable(store.category.category.categories)},
        suspended: store.category.suspend,
	};
}
export default connect(mapStateToProps)(Editprofile)
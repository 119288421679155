import axios from 'axios';
import API_URL from "../../Config"
import {Apiheader} from "../helpers/Apiheader"
import history from '../../history';

var configheaders = {
    headers: Apiheader()
}

export function fetchCategories(){
	return function(dispatch){
	axios.get(API_URL.API_PATH+'/admins/categories', configheaders)
	 	.then((response)=>{
			dispatch ({type: 'FETCH_CATEGORIES_FULLFILLED',payload:response.data})
		}).catch(function(error){
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}

export function fetchUserCategories(id){
	return function(dispatch){
	axios.get(API_URL.API_PATH+'/admins/user_categories/'+id, configheaders)
	 	.then((response)=>{
			dispatch ({type: 'FETCH_CATEGORIES_FULLFILLED',payload:response.data})
		}).catch(function(error){
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}

export function deleteCategory(groupId){
	return function(dispatch){
	axios.delete(API_URL.API_PATH+'/admins/delete_category/'+groupId, configheaders)
	 	.then((response)=>{
			dispatch ({type: 'DELETE_CATEGORY_FULLFILLED',payload:response.data})
		}).catch(function(error){
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}


export function addCategory(data){
	let params = data
	return function(dispatch){
	axios.post(API_URL.API_PATH+'/admins/add_and_edit_categories',params, configheaders)
	 	.then((response)=>{
			dispatch ({type: 'ADD_CATEGORY_FULLFILLED',payload:response.data})
		}).catch(function(error){
			alert(error.response.data.error.message)
			// dispatch ({type: 'ERROR_HANDLING',payload: error})
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}

export function getCategory(categoryId){
	return function(dispatch){
		axios.get(API_URL.API_PATH+'/admins/get_category/'+categoryId, configheaders)
		.then((response)=>{
		   dispatch ({type: 'GET_CATEGORY_FULLFILLLED',payload:response.data})
	   	}).catch(function(error){
		   // localStorage.clear()
		   // window.location.href="/"
	   	})
   }
}


export function members(groupId){
	return function(dispatch){
	axios.get(API_URL.API_PATH+'/admins/group_members/'+groupId, configheaders)
	 	.then((response)=>{
			dispatch ({type: 'FETCH_GROUP_MEMBER_FULLFILLED',payload:response.data})
		}).catch(function(error){
			// localStorage.clear()
			// window.location.href="/"
		})
	}
}
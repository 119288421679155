import React, {Component} from 'react';
import {connect} from 'react-redux';
// import { Link } from 'react-router-dom';
import {fetchUserdata} from '../../actions/userActions'
import { MDBDataTableV5 } from 'mdbreact';
import API_URL from "../../../Config"
export class Profile extends Component{


    constructor(props) {
        super(props);
        let query = new URLSearchParams(window.location.search);
        this.state = {
            id: query.get("id")
        }
          this.checkbox1=""
      
      }
    

	  componentWillMount(){
        this.props.dispatch(fetchUserdata(this.state.id));
    }




	render(){

        const { users } = this.props
        

		return (
            
            <div className="col-md-12">
                {users && 
                   <div className="card card-profile">
                <div className="card-avatar">
                  <a href="javascript:;">
                   
                    <img className="img" src={users && users.image &&  (users.image.url== null) ? "../assets/img/faces/marc.jpg":API_URL.BASE_URL+"/"+ users.image.url} />
                  </a>
                </div>
                <div className="card-body">
                    <h4 className="card-category text-gray">Name</h4>
                  <h4 className="card-title">{users.first_name} {users.last_name}</h4>
                  {users.role==0 && <h4 className="card-category text-gray">Phone Number</h4>}
                  {users.role==0 &&<h4 className="card-title">{"+ "+users.country_code + " "+users.phone_number}</h4>}
                  {users.role==2 && <h4 className="card-category text-gray">Email</h4>}
                  {users.role==2 &&<h4 className="card-title">{users.email}</h4>}
                  <h4 className="card-category text-gray">City</h4>
                  <h4 className="card-title">{users.city}</h4>
                  <h4 className="card-category text-gray">Bio</h4>
                  <p className="card-title">
                    {users.about_me}
                  </p>
                  {/* <a href="javascript:;" className="btn btn-primary btn-round">Suspend User</a> */}
                  {users.role==0 && <a href={"closet?id="+users.id} className="btn btn-primary btn-round">View Closet</a>}
                  {users.role==0 && <a href={"user_cat?id="+users.id} className="btn btn-primary btn-round">View Categories</a>}
                  {users.role==2 && <a href={"edit"} className="btn btn-primary btn-round">Edit Profile</a>}
                </div>
              </div>
              }     
          </div> 
        
		);
	}
}

const mapStateToProps = (store) => {
   
     
	return{
        users: store.user.user.user,
        // data_table:,
		userFetched: store.user.fetched,
	};
}

export default connect(mapStateToProps)(Profile)
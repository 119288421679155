import React, {Component} from 'react';
import {connect} from 'react-redux';
// import { Link } from 'react-router-dom';
// import {fetchGroup, suspend_group} from '../../actions/groupActions';
import {addCategory, getCategory} from "../../actions/categoryActions"
import API_URL from "../../../Config"
import { MDBDataTableV5 } from 'mdbreact';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Category } from '@material-ui/icons';

export class Categoryadd extends Component{
    constructor(props) {
        super(props);
       
          this.checkbox1=""

          let query = new URLSearchParams(window.location.search);

          let cat_id = query.get("id")

          

          this.state = {
            table: {},
            id: cat_id==null?"":cat_id,
            cat_name: "",
            selectedCat:{},
            add: true,
            add_name: false
          }
      
    }

    componentWillMount(){
      if(this.state.id!=""){
        this.props.dispatch(getCategory(this.state.id));
      }
    }

    

    handleValues = (e) => {
      var matches = e.target.value.match( /^\s+$/);
      if (matches== null ) {
        this.setState({cat_name: e.target.value, add_name: true})
      }

    }
    categorySubmit = (e) => {
      e.preventDefault()
      let data = {
        "category":{
          "id": this.props.categories&&this.props.categories.id!=""?this.props.categories.id: this.state.id,
          "name":  this.state.cat_name==""?this.props.categories.name: this.state.cat_name
        }
      }
   
        this.props.dispatch(addCategory(data));
      
    }

    onCancel = () => {
      this.setState({add: false})
      window.location.href = "/categories"
    }

	render(){

        const { categories, api_response, add} = this.props


		return (
      <div class="row">
             {api_response &&
                  <SweetAlert
                    showCloseButton
                    title={ add==true ? "Category added successfully." : "Category edited successfully."}
                    onConfirm={() => {this.onCancel()}}
                    onCancel={(e) => {this.onCancel()}}
                    customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
                    show={api_response && this.state.add}
                    
                    />
                }
          <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="card">
          <div class="card-header card-header-primary">
            <h4 class="card-title">{this.props.edit==true? "Edit" :"Add"} Category</h4>
            {/* <p class="card-category">Complete your profile</p> */}
          </div>
          <div class="card-body">
            <form onSubmit={(e)=>{this.categorySubmit(e)}}>
           
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="">Category Name</label>
                    <div class="form-group">
                      <input type="text" class="form-control" maxLength={50} onChange={(e)=>{
                        this.handleValues(e)
                      }} value={this.state.add_name==false&&categories&&this.state.cat_name==""?categories.name : this.state.cat_name} required/>
                     </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary pull-center">Submit</button>
              <div class="clearfix"></div>
            </form>
            </div>
            </div>
            </div>
            <div class="col-md-2"></div>
            </div>
		);
	}
}

const mapStateToProps = (store) => {

	return{
        categories:  store.category.category.category,
        userFetched: store.category.fetched,
        setTable: (e) => {this.setTable(store.category.category.categories)},
        suspended: store.category.suspend,
        add: store.category.category.add,
        edit:  store.category.edit,
        api_response: store.category.api_response
	};
}

export default connect(mapStateToProps)(Categoryadd)
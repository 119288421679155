import React, {Component} from 'react';

export class Privacy extends Component{
	render() {
	    return(
	    	<div className="center-div">

<div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="text-center">
                                <h1 className="heading">Privacy Policy</h1>
                            </div>
                            
                        </div>
                        <div className="col-12 privacy">
                                <h4>Thrifterz Privacy Policy </h4>
                                <p className="content"> Thrifterz is a multipurpose application that provides extensive features like instant messaging, Internet calling, and other services to users across the globe. Our Privacy Policy helps explain our information collection practices with its purpose.When we say <b>“Thrifterz,” “our,” “we,” or “us,”</b> we’re talking about Thrifterz LLC. This Privacy Policy (“Privacy Policy”) applies to all of our apps, services, features, software, and website (together, “Services”) unless specified otherwise.</p>

                                <h4>Information We Collect </h4>
                                <p className=""> Thrifterz receives or collects information when it is operated and provides its Services when you install, access, or use our ‘Services’.</p>
                                
                                <h3>Information Users Need to Provide</h3><br></br>
                                <h4>Your Account Information. </h4>
                                <p className="">You provide your mobile phone number to create a Thrifterz account. You provide us the phone numbers/contact numbers in your mobile address book on a regular basis, including those of both the users of our Services and your other contacts. This gives us confirmation that you are authorized to provide us such numbers with your full consent. You may also add other information to your account, such as a profile name, profile picture, etc.</p>

                                <h4>Your Messages </h4>
                                <p className="">We do not retain your messages in the ordinary course of action while we are providing our services to you. To improve performance and deliver media messages more efficiently, especially when multiple users are sharing a popular photo or video, we may retain that content on our servers for a prolonged period of time.</p>

                                <h4>Your Connections </h4>
                                <p className="">To help you organize your communication with others, we may create a ‘favorites list’ of your contacts and you can create, join, or get added to groups where such groups and lists get associated with your account information automatically.</p>

                                <h4>Usage and Log Information.</h4>
                                <p className="">We collect service-related, analytical, and performance information to keep them as a record. This includes data about your performed activities (such as how you use our Services, how you interact with others using our Services, etc.) that may include log files, diagnostic, crash, website, and performance logs, and reports.</p>


                                <h4>Transactional Information</h4>
                                <p className=""> If you pay for our Services, we may receive information and confirmations for the same, such as payment receipts, including from app stores or other third parties processing your payment.</p>

                                <h4>Device and Connection Information</h4>
                                <p className="">We intend to gather device-specific information when you install, access, or use our Services. This includes information such as hardware model, operating system information, browser information, IP address, mobile network information including phone number, and device identifiers. </p>


                                <h4>Status Information</h4>
                                <p className="">By this, we mean to collect information that confirms your online or offline status. </p>

                                <h4>Third-Party Providers.</h4>
                                <p className=""> We work with third-party service providers to help us operate, render, improve, understand, customize, support, and market our Services. For example, we work with companies to market our apps, provide our infrastructure, delivery, and other systems, supply maps and location information, process payments, etc. in order to help us understand and identify how people use our Services, and market our Services. Collaboration with third-party providers help us gain information about our users in certain circumstances and thus take a decisive step. (For example, app stores may provide us analytical reports to help us diagnose and fix concerning service issues.) </p>

                                <h4>Third-Party Services. </h4>
                                <p className=""> We allow you to use our Services in connection with third-party services. If you use our Services with such third-party services, we may receive information about you from them. Please note that when you use third-party services, their own terms and privacy policies will govern your use of those services. </p>

                                <h4>Information You and We Share </h4>
                                <p className=""> You share your information with us as you use and communicate through our Services, and we share your information to help us operate, provide, improve, understand, identify, customize, support, and market our Services in the best way possible. </p>


                                <h4>Account Information </h4>
                                <p className=""> Your phone number, profile name, and photo, online status and status message, and receipts may be available to anyone who uses our Services, although you can configure your Services’ settings to manage certain information that you wish to be made available to others </p>


                                <h4>Your Contacts and Others </h4>
                                <p className=""> Users with whom you interact may store or reshare your information (including your phone number, messages, images, etc.) with others through our Services. You can make use of Services’ settings to block unwanted users, thus, managing your account accordingly. You may choose selected users with whom you wish to communicate with and share certain information.</p>

                                <h4>Third-Party Providers. </h4>
                                <p className="content"> We work with third-party providers to help us operate, provide, improve, understand, customize, support, and market our Services. When we share information with third-party providers, we allow them to use your information in accordance with our instructions, terms, and conditions or with granted permission from you.</p>

                                <h4>Third-Party Services </h4>
                                <p className="content"> When you use third-party services that are integrated with our Services, they may receive information about what you share with them. If you interact with a third-party service linked through our Services, you may be providing information directly to them. Please note that when you use third-party services, their own terms and privacy policies are applied to your use of those services.</p>

                                <h4>How We Use Information </h4>
                                <p className="content"> We use all the information we have to help us operate, provide, improve, understand, customize, support, and market our Services.</p>


                                <h4>Our Services. </h4>
                                <p className="content"> We operate and provide our Services, including providing customer support, and improving, fixing, and customizing our Services. We understand how people use our Services, and analyze and use the information we have to evaluate and improve our Services, research, develop, and test new services and features, and conduct troubleshooting activities. We also use your information to respond to you when you contact us. </p>

                                <h4>Safety and Security </h4>
                                <p className="content"> We verify accounts and activity, and promote safety and security on and off our Services, such as by investigating suspicious activity or violations of our Terms, and to ensure our Services are being used legally. </p>

                                <h4>No Third-Party Banner Ads</h4>
                                <p className="content"> We do not allow third-party banner ads on Thrifterz. We have no intention to introduce them, but if we ever do, we will update this policy. </p>

                                <h4>Updates To Our Policy</h4>
                                <p className="content"> We may amend or update our Privacy Policy. Your continued use of our Services confirms your acceptance of our Privacy Policy, as amended. If you do not agree to our Privacy Policy, as amended, you must stop using our Services. Please review our Privacy Policy from time to time. </p>
                        </div>
                    </div>
                </div>
	        
	        </div>
	    )
  	}
}
export default (Privacy);
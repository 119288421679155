const initialState={
	fetching:false,
	fetched:false,
	delete: false,
	userdelete: false,
	suspend: false,
	api_response: false,
	edit:false,
	category:[],
	error:{}
}

export default function reducer(state=initialState, action){

	switch(action.type){

		case 'FETCH_GROUP':{
			return{...state,fetching:true}
			break;
		}
		case 'FETCH_CATEGORIES_FULLFILLED':{
			return{...state, fetching:false, fetched:true, category:action.payload, delete: false, userdelete: false, suspend: false}
			break;
        }
        case 'DELETE_CATEGORY_FULLFILLED':{
			return{...state, fetching:false, fetched:true, category:action.payload, delete: false, userdelete: false, suspend: false}
			break;
		}
		case 'ADD_CATEGORY_FULLFILLED':{
			return{...state, fetching:false, fetched:true, category:action.payload, api_response: true, edit:false}
			break;
		}
		case 'GET_CATEGORY_FULLFILLLED':{
			return{...state, fetching:false, fetched:true, category:action.payload, api_response: false,edit:true}
			break;
		}
		case 'ERROR_HANDLING':{
		
			return{...state, fetching:false, fetched:true, error:action.payload, api_response: false,edit:true}
			break;
		}

    }
    
	return state;
}
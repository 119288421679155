import React, {useEffect, useState} from 'react';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';




const Layout = (props) => {
  const access_token = localStorage.getItem('access_token');
  function getData(){
  }
if(props.type === 'loggedin') {
      if(access_token===null || access_token===undefined){
          //  userService.logout();
           return ""
         }
        
        return (
          <div className="wrapper">
            <Sidebar></Sidebar>
            <div className="main-panel">
              <Header></Header>
              <div class="content">
                <div class="container-fluid">
                 
                  {props.children}
                 
                </div>
              </div>
            </div>
                 
                
          </div>
        
        )
      }
    else if(props.type === 'guest' && (access_token===null || access_token===undefined)){
      
      return (
        <div className="wrapper">
           
            <div className="user_main-panel">
          
              <div class="content">
                <div class="container-fluid">
                
                  {props.children}
                 
                </div>
              </div>
            </div>
                 
                
          </div>
      )
    }
    else {
      
      return (
        <div className="wrapper">
           
            <div className="user_main-panel">
          
              <div class="content">
                <div class="">
                
                  {props.children}
                 
                </div>
              </div>
            </div>
                 
                
          </div>

      )
    }
}



export default Layout